import React from "react"
// ****************** Designed by Theewra. Developed by Explorelogy. ******************
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.min.css"
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"
import { GatsbyImage } from "gatsby-plugin-image"
import SwiperCore, { Navigation } from "swiper"

SwiperCore.use([Navigation])

const ClientsSlider = props => {
  return (
    <Swiper
      slidesPerView={5}
      spaceBetween={10}
      slidesPerGroup={1}
      loop={true}
      loopFillGroupWithBlank={true}
      navigation={{
        nextEl: ".swiper-button-next1",
        prevEl: ".swiper-button-prev1",
      }}
      className="mySwiper-col"
      breakpoints={{
        1400: {
          slidesPerView: 5,
        },
        1200: {
          slidesPerView: 4,
        },
        768: {
          slidesPerView: 3,
        },
        0: {
          slidesPerView: 1,
        },
      }}
    >
      {props.slides.map(slide => {
        return (
          <SwiperSlide key={slide.index}>
            <div className="sap-clients-image d-flex justify-content-center">
              <span>
                {/* {slide.index} */}
                <GatsbyImage
                  placeholder="blurred"
                  image={slide.image}
                  alt="techpac"
                  class="clientImg"
                />
              </span>
            </div>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

export default ClientsSlider
