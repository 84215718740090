import React from "react"
// ****************** Designed by Theewra. Developed by Explorelogy. ******************
import { StaticImage } from "gatsby-plugin-image"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/swiper.min.css"
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"
import SwiperCore, { Navigation } from "swiper"
import { GatsbyImage } from "gatsby-plugin-image"

SwiperCore.use([Navigation])

const TestimonialSlider = props => {
  return (
    <div>
      <Swiper
        slidesPerView={3}
        spaceBetween={10}
        slidesPerGroup={1}
        loop={true}
        loopFillGroupWithBlank={true}
        navigation={{
          nextEl: ".swiper-button-next1",
          prevEl: ".swiper-button-prev1",
        }}
        className="mySwiper-col"
        breakpoints={{
          1400: {
            maxWidth: 1400,
            slidesPerView: 3,
          },
          992: {
            maxWidth: 992,
            slidesPerView: 2,
          },
          768: {
            maxWidth: 768,
            slidesPerView: 1,
          },
          576: {
            maxWidth: 576,
            slidesPerView: 1,
          },
          0: {
            maxWidth: 0,
            slidesPerView: 1,
          },
        }}
      >
        {props.slides.map(slide => {
          console.log(slide.image)
          return (
            <SwiperSlide key={slide.index}>
              <div className="d-flex justify-content-center">
                <div className="testimonial-item">
                  <div className="testimonial-info-section d-flex">
                    <div className="testimonial-image">
                      <GatsbyImage
                        placeholder="blurred"
                        image={slide.image}
                        className="testimonial-image"
                        alt="techpac"
                      />
                    </div>
                    <div className="person-info">
                      <div className="person-logo">
                        <GatsbyImage
                          placeholder="blurred"
                          image={slide.logo}
                          className="testimonial-image mb-2"
                          alt="techpaclogo"
                        />
                      </div>
                      <div className="person-name">{slide.name}</div>
                      <div className="person-position">{slide.position}</div>
                      <div className="person-company">{slide.company}</div>
                    </div>
                  </div>
                  <div className="testimonial-info-description">
                    {slide.description}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

export default TestimonialSlider
