import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.min.css"
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"

const SapAccoladesSlider = props => {
  const [swiper, setSwiper] = useState(null)

  function slideToYear(index) {
    if (swiper) swiper.slideTo(index)
  }

  return (
    <div style={{ maxWidth: "80%" }}>
      <div className="client-slider-item accolades-slider">
        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          slidesPerGroup={1}
          loop={true}
          loopFillGroupWithBlank={true}
          navigation={{
            nextEl: ".swiper-button-next-white",
          }}
          className="mySwiper-col-sap"
          onSwiper={s => {
            setSwiper(s)
          }}
        >
          {props.slides.map(slide => {
            return (
              <>
                <SwiperSlide key={slide.index}>
                  <div className="sap-col-one d-flex flex-column">
                    <span>
                      <div className="d-flex mb-3 accolades-logos">
                        <GatsbyImage
                          image={slide.img1}
                          alt="techpac"
                          className="yearImage"
                        />
                        <GatsbyImage
                          image={slide.img2}
                          alt="techpac"
                          className="logoImage"
                        />
                        <GatsbyImage
                          image={slide.img3}
                          alt="techpac"
                          className="logoImage"
                        />
                        <GatsbyImage
                          image={slide.img4}
                          alt="techpac"
                          className="logoImage"
                        />
                      </div>
                    </span>
                    <p className="">{slide.line1}</p>
                    <p className="">{slide.line2}</p>
                    <p className="">{slide.line3}</p>
                    <p className="sap-slider-last-ittem">{slide.line4}</p>
                  </div>
                </SwiperSlide>
              </>
            )
          })}
        </Swiper>
      </div>
      <div className="pagination-wrapper">
        {props.slides.map(slide => {
          return (
            <span
              onClick={() => slideToYear(slide.index + 1)}
              className="sap-pagination"
            >
              {slide.year}
            </span>
          )
        })}
      </div>
    </div>
  )
}
export default SapAccoladesSlider
